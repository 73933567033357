import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Row, Col } from 'react-flexbox-grid';
import pushToSentry from '../../helpers/pushToSentry'
import img from '../images/about-hero.png'
import RecipeIcon from '../images/RecipeIcon'
import WorkoutAboutIcon from '../images/WorkoutAboutIcon'
import PlanningIcon from '../images/PlanningIcon'
import CommunityIcon from '../images/CommunityIcon'
import SeanAvartar from '../images/sean.png'
import SarahAvartar from '../images/sarah.png'
import KaciAvartar from '../images/kaci.png'
import TimelessAvartar from '../images/timeless.png'
import FacebookOutlineIcon from '../images/FacebookOutlineIcon';
import VimeoOutlineIcon from '../images/VimeoOutlineIcon';
import InstaOutlineIcon from '../images/InstaOutlineIcon';
import TwitOutlineIcon from '../images/TwitOutlineIcon';
import PinOutlineIcon from '../images/PinOutlineIcon';
import map from '../images/map.png'
import FullScreenLeft from '../images/about-fullscreen-left.jpg'
import FullScreenRight from '../images/about-fullscreen-right.png'
import PlayButtonIcon from '../images/PlayButton';

import { appEssentialFetch, addSentryError } from "../../actions";
import ModalVideo from '../videos/components/modalVideoLib';
import axios from "axios";
import { baseUrl } from "../../helpers/api";
import { TransitionEaseInOne, TransitionTwo, TransitionThree, TransitionFour } from '../common/transitions';

const AboutPage = (props) => {

  const [videoState, setVideoState] = useState({
    videoId: null,
    openVideoModal: false
  })

  const [countState, setCountState] = useState({
    isFetching: false,
    isFetched: false,
    data: null,
    isError: false
  })

  useEffect(() => {
    props.appEssentialFetch();
    axios({
      method: 'GET',
      url: `${baseUrl}/api/feature-counts`,
    }).then((response) => {
      setCountState({
        ...countState,
        isFetching: false,
        isFetched: true,
        data: response.data
      });
    }).catch(e => {
      const eventId = pushToSentry(e)

      props.addSentryError({
        error: e.message,
        eventId
      })
      setCountState({
        ...countState,
        isFetching: false,
        isFetched: true,
        isError: true
      });
    })
  }, [])

  return (
    <>
      <Helmet>
        <title>About Us | Dashing Dish</title>
        <meta name="description" content="Dashingdish About page" />
      </Helmet>
      <TransitionEaseInOne>
        <section
          className="about-hero"
          style={{
            backgroundImage: `url(${img})`
          }}>
          <div className="container">
            <div className="about-hero-wrapper">
              <div className="play-icon">
                {/* TODO: replace videoId to actual one */}
                <figure className="play-button" onClick={() => setVideoState({ videoId: '389169506', openVideoModal: true })}>
                  <PlayButtonIcon></PlayButtonIcon>
                </figure>
              </div>
              <h1>The heart behind Dashing Dish</h1>
            </div>
          </div>
        </section>
      </TransitionEaseInOne>
      <main
        className="about-page">
        <section className="mission">
          <div className="container">
            <TransitionTwo>
              <div className="mission-hero-content">
                <h2>Our Mission</h2>
                <p>Healthy living doesn’t have to be overwhelming.
                  We believe in empowering you with the tools you need to make longlasting, healthy choices that help you feel your best.
                  From nourishing recipes and energizing workouts to inspiring devotionals and community, we are committed to providing you with the resources you need to build the healthy lifestyle that’s right for you.</p>
                <div className="mission-list">
                  <ul className="mission-no-style">
                    <li><a href='/about/my-story' onClick={(e) => { e.preventDefault(); props.history.push('/about/my-story') }}>Read My Testimony</a></li>
                    <li><a href='/press-kit' onClick={(e) => { e.preventDefault(); props.history.push('/press-kit') }}>View Our Press Kit</a></li>
                    <li><a href='/about/my-story' onClick={(e) => { e.preventDefault(); props.history.push('/about/my-story') }}>Watch My Story</a></li>
                  </ul>
                </div>
              </div>
            </TransitionTwo>
            <TransitionThree>
              <div className="mission-pillars">
                <h3>The four pillars of living a healthy lifestyle</h3>
                <div className="pillars-list">
                  <ul>
                    <li>
                      <div className="card" onClick={(e) => props.history.push("/recipes")}>
                        <figure>
                          <RecipeIcon></RecipeIcon>
                        </figure>
                        <h5>Clean Recipes</h5>
                        <p>Mouth-watering, simple,and always made with clean ingredients.</p>
                        <small>{(countState.data && countState.data.recipes_count) ? `${countState.data.recipes_count} RECIPES` : null} </small>
                      </div>
                    </li>
                    <li>
                      <div className="card" onClick={(e) => props.history.push("/workouts")}>
                        <figure>
                          <WorkoutAboutIcon></WorkoutAboutIcon>
                        </figure>
                        <h5>Workouts</h5>
                        <p>At the gym or home. All experience levels and video tutorials included!</p>
                        <small>{(countState.data && countState.data.workouts_count) ? `${countState.data.workouts_count} WORKOUTS` : null}</small>
                      </div>
                    </li>
                    <li>
                      <div className="card" onClick={(e) => props.history.push("/calendar")}>
                        <figure>
                          <PlanningIcon></PlanningIcon>
                        </figure>
                        <h5>Planning</h5>
                        <p>Save time with pre-made plans and the tools to customize your own.</p>
                        <small>{(countState.data && countState.data.meal_plans_count) ? `${countState.data.meal_plans_count} MEAL PLANS` : null}</small>
                      </div>
                    </li>
                    <li>
                      <div className="card">
                        <a href="https://www.facebook.com/groups/224062567941102/" target="_blank">
                          <figure>
                            <CommunityIcon></CommunityIcon>
                          </figure>
                          <h5>Community</h5>
                          <p>Join like-minded <br></br>&nbsp;people and succeed together.</p>
                          <small>1.7K  FB MEMBERS</small>
                        </a>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </TransitionThree>
          </div>
        </section>
        <section className="about-image-section">
          <div className="container">
            <TransitionFour>
              <Row className="image-wrapper no-margin">
                <Col xs={12} lg={6} xl={5} className="FullScreen-left no-padding">
                  <figure>
                    <img src={FullScreenLeft} className="img_fluid"></img>
                  </figure>
                </Col>
                <Col xs={12} lg={6} xl={7} className="FullScreen-right no-padding">
                  <div className="founder-wrapper">
                    <h3>Meet the founder, author,<br></br>
                      and chef behind Dashing Dish
                    </h3>
                    <p>Katie Farrell is the founder and face of Dashing Dish, a healthy
                      lifestyle website and recipe subscription service designed to nourish
                      readers’ minds, body and souls. A registered nurse, author, blogger,
                      healthy lifestyle advocate, and mother, Katie launched Dashing Dish
                      to teach women across the across the country to end misconception
                      about healthy eating and prove that it is easy and budget-friendly to
                      make delicious, healthy food balance in their physical, mental,
                      emotional and spiritual healthy, all while enjoying the journey.
                      <br></br>
                      <br></br>
                      Katie has authored three books: “Dashing Dish: 100 Simple & <br></br> Delicious
                      Recipes for Clean Eating,” “Devotions for a Healthier You: Feeding
                      Mind, Body, and Soul;” and “Nourish: Discover God’s  <br></br> Perfectly
                      Balanced Plan for Your Body and Soul.” Katie lives in Tennessee with
                      her high school sweetheart and daughter.</p>
                    <div className="outline-social-icons">
                      <ul className="outline-icons-lists">
                        <li>
                          <a href="https://www.facebook.com/dashingdish/" target="_blank">
                            <FacebookOutlineIcon></FacebookOutlineIcon>
                          </a>
                        </li>
                        <li>
                          <a href="https://vimeo.com/dashingdish" target="_blank">
                            <VimeoOutlineIcon></VimeoOutlineIcon>
                          </a>
                        </li>
                        <li>
                          <a href="https://www.pinterest.com/dashingdish/" target="_blank">
                            <PinOutlineIcon></PinOutlineIcon>
                          </a>
                        </li>
                        <li>
                          <a href="https://www.instagram.com/dashingdish/?hl=en" target="_blank">
                            <InstaOutlineIcon></InstaOutlineIcon>
                          </a>
                        </li>
                        <li>
                          <a href="https://twitter.com/dashingdish?lang=en" target="_blank">
                            <TwitOutlineIcon></TwitOutlineIcon>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </Col>
              </Row>
            </TransitionFour>
          </div>
        </section>
        <section className="founder">
          <div className="container">
            <div className="rest-of-team">
              <h3>And the rest of the team</h3>
              <ul className="team-list">
                <li className="card">
                  <figure>
                    <img src={SeanAvartar} className="img_fluid"></img>
                  </figure>
                  <h5>Sean Farrell</h5>
                  <small>Creative Director</small>
                </li>
                <li className="card">
                  <figure>
                    <img src={SarahAvartar} className="img_fluid"></img>
                  </figure>
                  <h5>Sarah Shaffer</h5>
                  <small>Health Coach</small>
                </li>
                <li className="card">
                  <figure>
                    <img src={KaciAvartar} className="img_fluid"></img>
                  </figure>
                  <h5>Kaci Cheeseman</h5>
                  <small>Health Coach</small>
                </li>
                <li className="card">
                  <figure>
                    <img src={TimelessAvartar} className="img_fluid"></img>
                  </figure>
                  <h5>Timeless Co</h5>
                  <small>Development</small>
                </li>
              </ul>
            </div>
          </div>
        </section>
        <section className="mainObjective">
          <div className="container">
            <Row className="objective-wrapper no-margin">
              <Col ms={12} lg={6} className="no-padding">
                <div className="objective-content">
                  <h3>Giving back is our main objective</h3>
                  <p>
                    Dashing Dish has always been 100% member supported.
                    That means we are genuinely invested in helping members live healthier lives.
                    No ads. No sponsorships. No investors. No gimmicks.
                    Just real healthy resources and the support you need to achieve your goals.
                  </p>
                  <p>
                    What’s more, we want Dashing Dish to be a place where the hungry (both physically and spiritually) can go to find nourishment.
                    By giving to those in need, we enrich our lives too and make the world a better place.
                    Interested in featuring Dashing Dish?<a href="/press-kit" onClick={(e) => { e.preventDefault(); props.history.push("/press-kit") }}>&nbsp;View our press kit.</a>
                  </p>
                </div>
              </Col>
              <Col ms={12} lg={6} className="no-padding">
                <div className="map">
                  <figure>
                    <img style={{ maxHeight: '450px' }} src={map} className="img_fluid"></img>
                  </figure>
                </div>
              </Col>
            </Row>
          </div>
        </section>
        <section className="partnering">
          <div className="container">
            <div className="partnering-wrapper">
              <p>Partnering with <a href="http://ordinaryhero.org/" target="_blank">Ordinary Hero</a></p>
              <h3>With our member’s support, we’ve been able
                to feed over <span>2,500</span> kids since 2018</h3>
              {/* <ul>
              <li>
                <h4>37</h4>
                <small>Families Supported</small>
              </li>
              <li>
                <h4>450</h4>
                <small>Meals Fed</small>
              </li>
              <li>
                <h4>7</h4>
                <small>Trips funded</small>
              </li>
            </ul> */}
            </div>
          </div>
        </section>
        {
          videoState.openVideoModal &&
          <ModalVideo
            channel={'vimeo'}
            isOpen={videoState.openVideoModal}
            videoId={videoState.videoId}
            vimeo={{ autoplay: 1, autopause: 0 }}
            onClose={() => {
              document.body.classList.remove('ReactModal__Body--open');
              setVideoState({ videoId: '389169401', openVideoModal: false })
            }} />
        }
      </main>
    </>
  )
}

const mapStateToProps = () => ({})

const dispatchActionToProps = dispatch => ({
  addSentryError: bindActionCreators(addSentryError, dispatch),
  appEssentialFetch: bindActionCreators(appEssentialFetch, dispatch),
})

export default connect(mapStateToProps, dispatchActionToProps)(AboutPage);