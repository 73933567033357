import React, { useState, useEffect } from "react";
import { map } from "lodash";

import IngredientRow from "./ingredientRow";
import IngredientRowV2 from "./ingredientRowV2";
import IngredientOptionRow from "./ingredientOptionRow";
import IngredientFooter from "./ingredientFooter";
import Button from "../../common/button";
import Selector from "../../common/selector";
import Loader from "../../common/contentLoader";
import { Tooltip } from "react-tippy";

const RecipeIngredients = (props) => {
  const [state, setState] = useState({
    servingValue: props.defaultServing,
    openDropdown: false,
  });
  const [planPopover, showPlanPopover] = useState(false);

  const {
    groups,
    isLoggedIn,
    ingredients,
    selectedCount,
    groceryWaitList,
    recipeId,
    setCurrentServing,
    addToGroceryList,
    fetchStatus,
    defaultServing,
    userType,
    isAuthenticated,
    ingredientsV2,
    variationTotalCount
  } = props;
  // console.log('ingredients ', ingredientsV2);
  useEffect(() => {
    setState({
      ...state,
      servingValue: parseInt(defaultServing),
    });
  }, [defaultServing]);

  const premiumPlan = userType === "premiumPlan" || userType === "grandfather";
  const btnText = `Add (${selectedCount}) ingredients to grocery list`;
  const selectorList = map(new Array(50), (item, index) => {
    return { title: index + 1, value: `${index + 1}` };
  });

  if (fetchStatus.objectDetails.recipe[0].isFetching) {
    return <Loader height="600px"></Loader>;
  } else {
    const variations = map(props.variation, (variation, index) => ({
      label: variation.name,
      title: variation.name,
      value: index,
      id: variation.id,
    }));
    return (
      <div className="ingredients">
        <div className="ingredientsHead">
          <h2>
            Ingredients
            <div
              className="dropdownBlock"
              onClick={() =>
                setState((prevState) => ({
                  ...prevState,
                  openDropdown: !state.openDropdown,
                }))
              }
            >
              <Selector
                label={"Servings"}
                class={"customSelect"}
                value={state.servingValue}
                onSelectValue={(value) => {
                  setState((prevState) => ({
                    ...prevState,
                    servingValue: value,
                  }));
                  setCurrentServing(value);
                }}
                selectorList={selectorList}
              />
            </div>
          </h2>
        </div>
        <div className="groups">
          {props.variation && props.variation.length > 1 && (
            <div className="swapout-dropdownBlock">
              <div className="swapout">
                <h3>Swap out</h3>
              </div>
              <div className="swapout-dropdown">
                <Selector
                  class={"customSelect"}
                  selectorList={variations}
                  width="180px"
                  onSelectValue={(value) => {
                    variations.map((variant, index) => {
                      if (index === parseInt(value)) {
                        // value will be string
                        props.changeRecipeVariation(null, value);
                      }
                    });
                  }}
                />
              </div>
            </div>
          )}
          <div className="new-ingredients">
            {

              map(ingredientsV2, (ingredient) => {
                if (ingredient.header) {
                  return (
                    <IngredientOptionRow
                      key={ingredient.id}
                      amount={ingredient.item || ingredient.quantity}
                    />
                  )
                } else {
                  return (
                    <IngredientRowV2
                      key={ingredient.id}
                      ingredient={ingredient}
                      isLoggedIn={isLoggedIn}
                      recipeId={recipeId}
                    />
                  )
                }

              })
            }
          </div>

          {/* <h2>
            Old Ingredients
          </h2> */}
          {/* <div className="old-ingredients">
            {map(groups, (group, index) => {
              return (
                <div className="group" key={index}>
                  {group.name !== null && group.name !== "" ? (
                    <h4 className="groupName">{group.name}:</h4>
                  ) : null}
                  <ul className="unstyled">
                    {map(group.lines, (ingredient, index) =>
                      ingredient.item !== "" ? (
                        <IngredientRow
                          key={index}
                          amount={ingredient.amount}
                          selected={ingredient.selected}
                          ingredient={ingredient}
                          item={ingredient.item}
                          isLoggedIn={isLoggedIn}
                          recipeId={recipeId}
                        />
                      ) : (
                        <IngredientOptionRow
                          key={index}
                          amount={ingredient.amount}
                        />
                      )
                    )}
                  </ul>
                </div>
              );
            })}
          </div> */}
        </div>
        {
          <Tooltip
            multiple={true}
            open={planPopover}
            arrow={true}
            trigger="click"
            theme="light"
            interactive={true}
            position="bottom-start"
            onRequestClose={() => showPlanPopover(false)}
            html={
              <div className="Addgroceries">
                <div className="groceriesHead">
                  <h3>
                    {isAuthenticated
                      ? `Upgrade your plan to access Grocery List Manager`
                      : `Signup to access this feature`}
                  </h3>
                </div>
                <div className="groceriesFooter">
                  <Button
                    btnClass="primary btn-full"
                    btnText={
                      props.isAuthenticated
                        ? "Upgrade"
                        : "See Plans and Pricing"
                    }
                    onClick={(e) => {
                      e.preventDefault();
                      if (props.isAuthenticated) {
                        props.history.push("/account/membership");
                      } else {
                        props.history.push("pricing");
                      }
                    }}
                  />
                </div>
              </div>
            }
          >
            <Button
              btnClass="primary btn-full"
              disabled={selectedCount === 0}
              btnText={btnText}
              onClick={() =>
                !premiumPlan || !isAuthenticated
                  ? showPlanPopover(true)
                  : addToGroceryList(groceryWaitList)
              }
            ></Button>
          </Tooltip>
        }
        {ingredients && ingredients.footnotes && (
          <IngredientFooter footnotes={ingredients && ingredients.footnotes} />
        )}
      </div>
    );
  }
};
export default RecipeIngredients;
