import React, { useEffect, useState } from "react";

import { loadStripe } from '@stripe/stripe-js';
import { Elements } from "@stripe/react-stripe-js";
const stripePromise = loadStripe(process.env.STRIPE_KEY);

import ReCAPTCHA from "react-google-recaptcha";


import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";

import toJS from "../../../../helpers/toJS";
import { getRegisterStatus, getInitiatedEmail } from "../../../../selectors";
import {
  assignPlanUnified,
  showToastMessage,
  appEssentialFetch,
  completeRegistrationUnified,
  couponActivate
} from "../../../../actions";

import Checkout from "./checkout";
import { propTypes } from "react-safe-html/lib/ReactSafeHtml";

const App = ({
  unifiedRegisterState,
  registerStatus,
  history,
  plans,
  appEssentialFetch,
  completeRegistrationUnified,
  couponActivateInitiate,
  couponActivateSuccess,
  couponActivateFailure,
  couponRemove,
  showToastMessage,
  initiatedEmail
}) => {
  const [paymentType, changePaymentType] = useState("stripe");
  const [isCatchpaVerified, setIsCatchpaVerified] = useState(true);

  const onChange = (value) => {
    setIsCatchpaVerified(true);
  }
  useEffect(() => {
    if (registerStatus.loggedIn) {
      history.push("/");
    }

  }, [registerStatus.loggedIn, registerStatus.errorMessage]);

  useEffect(() => {
    if (
      (!unifiedRegisterState.planId || !unifiedRegisterState.duration) &&
      !unifiedRegisterState.gift.data.giftCode
    ) {
      history.push("pricing");
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>Payment | Dashing Dish</title>
        <meta name="description" content="Dashingdish Payment page" />
      </Helmet>
      {
        !isCatchpaVerified ? (
          <div style={{ margin: '30px auto', maxWidth: '300px' }}>
            <ReCAPTCHA
              sitekey="6Ldmc64oAAAAAEt9xjhFI4LfAbkmcGW0-yzRw1Uc"
              onChange={onChange}
            />
          </div>
        ) : (

          <Elements stripe={stripePromise}>
            <Checkout
              completeRegistration={() => { }}
              unifiedRegisterState={unifiedRegisterState}
              plans={plans}
              paymentType={paymentType}
              changePaymentType={changePaymentType}
              completeRegistrationUnified={completeRegistrationUnified}
              couponActivateInitiate={couponActivateInitiate}
              couponActivateSuccess={couponActivateSuccess}
              couponActivateFailure={couponActivateFailure}
              couponRemove={couponRemove}
              showToastMessage={showToastMessage}
              initiatedEmail={initiatedEmail}
              errorMessage={registerStatus.errorMessage}
            />
          </Elements>
        )
      }
    </>
  );
};

const mapStateToProps = (state) => ({
  registerStatus: getRegisterStatus(state),
  plans: state.getIn(["essentials", "plans"]),
  unifiedRegisterState: state.getIn(["unifiedRegister"]),
  initiatedEmail: getInitiatedEmail(state),
});

const dispatchActionToProps = (dispatch) => ({
  appEssentialFetch: bindActionCreators(appEssentialFetch, dispatch),
  completeRegistrationUnified: bindActionCreators(
    completeRegistrationUnified,
    dispatch
  ),
  couponActivateInitiate: bindActionCreators(couponActivate.initiate, dispatch),
  couponActivateSuccess: bindActionCreators(couponActivate.success, dispatch),
  couponActivateFailure: bindActionCreators(couponActivate.failure, dispatch),
  couponRemove: bindActionCreators(couponActivate.remove, dispatch),
  showToastMessage: bindActionCreators(showToastMessage, dispatch),
});

export default connect(mapStateToProps, dispatchActionToProps)(toJS(App));
