import React, { useEffect, useState } from "react";
import ReactGA from "react-ga";
import moment from 'moment';
import { AnimatedSwitch } from "react-router-transition";
import {
  BrowserRouter as Router,
  withRouter,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import { bindActionCreators } from "redux";
import Helmet from "react-helmet";
import { connect } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import toJS from "../../helpers/toJS";
import modalImg from "../images/modal-katie-img.png";
import {
  getAuthStatus,
  getToastMessage,
  getGroceryListThresholdNotification,
} from "../../selectors";
import {
  appEssentialFetch,
  removeGroceryListLimitNotification,
  replaceIngredients,
  acceptFirstTime,
  changeFooterStatus,
  acceptSecondTime,
} from "../../actions";
import ScrollMemory from "./scrollMemory";
import Layout from "../layout";
import Login from "../auth/components/login";
import Register from "../auth/components/register";
import ChoosePlan from "../auth/components/choosePlan";
import Payment from "../auth/components/payments";
import ResetPassword from "../auth/components/resetPassword";
import GiftMembership from "../auth/components/giftMembership";
import GiftMembershipSuccess from "../auth/components/giftMembershipSuccess";
import Recipes from "../recipes";
import RecipeIndex from "../recipeIndex";
import GroceryList from "../groceryList";
import MealPlan from "../mealPlan";
import Page404 from "../404";
import HomePage from "../home";
import AboutPage from "../about";
import StoryPage from "../story";
import TvshowPage from "../tvshow";
import Application from "../application";
import Contact from "../contact";
import GetStart from "../getStart";
import PressKit from "../pressKit";
import Workouts from "../workouts";
import CardGiveaway from "../cardGiveaway";
import WorkoutIndex from "../workoutIndex";
import Faqs from "../faqs";
import PrintGroceryList from "../groceryList/components/PrintGroceryList";
import ForgetPassword from "../../web/auth/components/ForgotPassword";
import PrintRecipe from "../../web/recipeIndex/components/PrintRecipe";
import PrintWorkout from "../../web/workoutIndex/components/PrintWorkout";
import Account from "../account";

import BlogList from "../../web/blog/list";
import BlogPreview from "../../web/blog/preview";
import BlogIndex from "../../web/blog/interior";

import MealPlanPrint from "../mealPlan/components/PrintCalendar";
import Videos from "../../web/videos";
import VideoIndex from "../../web/videoIndex";
import Favorite from "../favorite";
import Book from "../books";
import ModalImg from "../images/modalpic.png";
import "../../web/styles/common.scss";
// import { Slide } from 'pure-react-carousel';
import { Slide } from "react-toastify";
import GroceryListThresholdModal from "../common/groceryListThresholdModal";
import ModalWrapper from "../common/modal";
import Button from "../common/button";
import Footer from "../layout/footer";
import isNil from "lodash/isNil";
import AvailGift from "../auth/components/availGift";
import ActivateGift from "../auth/components/activateGift";
import Referral from "../auth/components/referral";
import DishList from "../dishlist";
import Resource from "../resource";
import reactivateAccount from "../account/reactivate-account";
import { createBrowserHistory } from "history";
import PrivacyPolicyPage from "../privacy-policy/privacy-policy";
import CookiePolicyPage from "../privacy-policy/cookie-policy";
import TermsConditionPage from "../privacy-policy/terms-condition";
import pushToSentry from "../../helpers/pushToSentry";
import TopBanner from "../layout/topBanner";

import KatiesCornerRoutes from "../katiesCorner";
import MealPlanGiveaway from "../cardGiveaway/mealplanGiveaway";


const PrivateRoute = ({
  component: Component,
  isAuthenticating,
  isAuthenticated,
  isAuthenticationFailed,
  ...restProps
}) => {
  return (
    <Route
      {...restProps}
      render={(props) => {
        if (isAuthenticating || isAuthenticated || !isAuthenticationFailed) {
          return (
            <Component
              {...props}
              isAuthenticating={isAuthenticating}
              isAuthenticated={isAuthenticated}
              isAuthenticationFailed={isAuthenticationFailed}
            />
          );
        } else if (isAuthenticationFailed) {
          return <Redirect to="/login" />;
        } else {
          return <Redirect to="/login" />;
        }
      }}
    />
  );
};

const PublicRoute = ({
  component: Component,
  isAuthenticating,
  isAuthenticated,
  isAuthenticationFailed,
  ...restProps
}) => {
  return (
    <Route
      {...restProps}
      render={(props) => {
        return (
          <Component
            {...props}
            isAuthenticating={isAuthenticating}
            isAuthenticated={isAuthenticated}
            isAuthenticationFailed={isAuthenticated}
          />
        );
      }}
    />
  );
};

const RedirectRoute = () => {
  return <Redirect to="/" />;
};

const Routes = (props) => {
  const authDetails = {
    isAuthenticating:
      props.loginStatus.isLoggingIn || props.loginStatus.isAuthenticating,
    isAuthenticated: props.loginStatus.isLoggedIn,
    isAuthenticationFailed: props.loginStatus.authError,
  };


  const [hasCompletedForTheMonth, setHasCompletedForTheMonth] = useState(true);

  useEffect(() => {
    window.addEventListener("online", () => pushToSentry("Connection is back"));
    window.addEventListener("offline", () =>
      pushToSentry("Connection is lost")
    );

    const hasCompletedForTheMonthLastDate = localStorage.getItem('hasCompletedForTheMonthLastDate');

    if ((moment().month() != hasCompletedForTheMonthLastDate)) {
      setHasCompletedForTheMonth(false);
    }

  }, []);

  // console.log('has ', hasCompletedForTheMonth);
  useEffect(() => {
    if (props.toasts.show) {
      if (props.toasts.type === "success") {
        toast.success(props.toasts.message, {
          position: toast.POSITION.BOTTOM_CENTER,
        });
      }
      if (props.toasts.type === "error") {
        toast.error(props.toasts.message, {
          position: toast.POSITION.BOTTOM_CENTER,
        });
      }
    }
  }, [props.toasts.show, props.toasts.message, props.toasts.type]);

  // for google analytics
  const history = createBrowserHistory();
  const location = history.location;
  ReactGA.set({ page: location.pathname + location.search });
  ReactGA.pageview(location.pathname + location.search);


  return (
    <>
      <Router disableScrollToTop={true}>
        <Helmet>
          <title>Dashing Dish</title>
        </Helmet>
        <ScrollMemory />
        <Layout {...authDetails} />
        <ToastContainer
          newestOnTop
          rtl={false}
          pauseOnVisibilityChange
          draggable
          closeButton={false}
          autoClose={2000}
          hideProgressBar
          transition={Slide}
        />
        <GroceryListThresholdModal
          replaceIngredients={props.replaceIngredients}
          groceryListThreshold={props.groceryListThreshold}
          removeGroceryListLimitNotification={
            props.removeGroceryListLimitNotification
          }
        />
        <Switch
          atEnter={{ opacity: 0 }}
          atLeave={{ opacity: 0 }}
          atActive={{ opacity: 1 }}
          className="switch-wrapper"
        >
          <PublicRoute path="/login" component={Login} {...authDetails} />
          <PublicRoute
            exact
            path="/forget-password"
            component={ForgetPassword}
          />

          <PublicRoute
            path="/pricing"
            component={ChoosePlan}
            {...authDetails}
          />
          <PublicRoute path="/initiate-register" component={Register} {...authDetails} />
          <PublicRoute path="/register" component={Payment} {...authDetails} />
          <PrivateRoute
            exact
            path="/recipes/fav-boards"
            component={Favorite}
            {...authDetails}
            objectType={"recipe"}
          />
          <PublicRoute
            exact
            path="/recipe/:recipeSlug"
            component={RecipeIndex}
            {...authDetails}
          />
          <PrivateRoute
            exact
            path="/recipes/favorites"
            component={Recipes}
            {...authDetails}
          />

          <PrivateRoute
            exact
            path="/recipe/:recipeSlug/print"
            component={PrintRecipe}
            {...authDetails}
          />
          <PublicRoute
            exact
            path="/recipes/featured/:featuredCollection"
            component={Recipes}
            {...authDetails}
          />
          <PublicRoute
            exact
            path="/recipes"
            component={Recipes}
            {...authDetails}
          />

          <PublicRoute
            exact
            path="/recipes/favorites/:favoriteBoardSlug"
            component={Recipes}
            {...authDetails}
          />
          <PrivateRoute
            exact
            path="/grocery-list"
            component={GroceryList}
            {...authDetails}
          />
          <PrivateRoute
            exact
            path="/grocery-list/print"
            component={PrintGroceryList}
            {...authDetails}
          />

          <PublicRoute
            exact
            path="/workouts/favorites"
            component={Workouts}
            {...authDetails}
          />
          <PrivateRoute
            exact
            path="/workouts/fav-boards"
            component={Favorite}
            {...authDetails}
            objectType={"workout"}
          />
          <PrivateRoute
            exact
            path="/workouts/favorites/:favoriteBoardSlug"
            component={Workouts}
            {...authDetails}
          />
          <PublicRoute
            exact
            path="/workouts/featured/:featuredCollection"
            component={Workouts}
            {...authDetails}
          />
          <PublicRoute
            exact
            path="/workouts/:workoutType/:workoutTaxonomy"
            component={Workouts}
            {...authDetails}
          />
          <PublicRoute
            exact
            path="/workouts"
            component={Workouts}
            {...authDetails}
          />
          <PublicRoute
            exact
            path="/workout/:workoutSlug"
            component={WorkoutIndex}
            {...authDetails}
          />
          <PublicRoute
            exact
            path="/workout/:workoutSlug/print"
            component={PrintWorkout}
            {...authDetails}
          />
          <PrivateRoute
            exact
            path="/blog/fav-boards"
            component={Favorite}
            {...authDetails}
            objectType={"blog"}
          />
          <PublicRoute
            exact
            path="/blog/preview"
            component={BlogPreview}
            {...authDetails}
          />
          <PublicRoute
            exact
            path="/blog/favorites"
            component={BlogList}
            {...authDetails}
          />
          <PrivateRoute
            exact
            path="/blog/favorites/:favoriteBoardSlug"
            component={BlogList}
            {...authDetails}
          />
          <PublicRoute
            exact
            path="/blog"
            component={BlogList}
            {...authDetails}
          />
          <PublicRoute
            exact
            path="/blog/topic/:blogTopic"
            component={BlogList}
            {...authDetails}
          />
          <PublicRoute
            exact
            path="/blog/:blogSlug"
            component={BlogIndex}
            {...authDetails}
          />
          <PublicRoute
            exact
            path="/videos/favorites"
            component={Videos}
            {...authDetails}
          />
          <PublicRoute
            exact
            path="/videos/favorites/:favoriteBoardSlug"
            component={Videos}
            {...authDetails}
          />
          <PublicRoute
            exact
            path="/videos"
            component={Videos}
            {...authDetails}
          />
          <PublicRoute
            exact
            path="/video/:videoSlug"
            component={VideoIndex}
            {...authDetails}
          />
          <PublicRoute exact path="/faqs" component={Faqs} {...authDetails} />
          <PublicRoute
            exact
            path="/get-started"
            component={GetStart}
            {...authDetails}
          />
          <PrivateRoute
            exact
            path="/calendar/print"
            component={MealPlanPrint}
            {...authDetails}
          />
          <PrivateRoute
            exact
            path="/calendar"
            component={MealPlan}
            {...authDetails}
          />
          <PrivateRoute path="/account" component={Account} {...authDetails} />
          <PublicRoute path="/reset-password" component={ResetPassword} />
          <PublicRoute
            path="/shop/gift-memberships"
            component={GiftMembership}
            {...authDetails}
          />
          <PublicRoute
            path="/activate/:gift_code"
            component={AvailGift}
            {...authDetails}
          />
          <PublicRoute
            path="/activate-gift"
            component={ActivateGift}
            {...authDetails}
          />
          <PublicRoute
            path="/referral/:referral_code"
            component={Referral}
            {...authDetails}
          />
          <PublicRoute path="/dishlist/:category?" component={DishList} />
          {/* <PublicRoute exact path="/"
            component={RedirectRoute}
          /> */}
          <PublicRoute exact path="/" component={HomePage} {...authDetails} />

          <PublicRoute
            exact
            path="/grocery-card-giveaway"
            component={CardGiveaway}
            {...authDetails}
          />
          <PublicRoute
            exact
            path="/exclusive-content"
            component={MealPlanGiveaway}
            {...authDetails}
          />
          <PublicRoute
            exact
            path="/about/my-story"
            component={StoryPage}
            {...authDetails}
          />
          <PublicRoute
            exact
            path="/about"
            component={AboutPage}
            {...authDetails}
          />
          <PublicRoute
            exact
            path="/app"
            component={Application}
            {...authDetails}
          />
          <PublicRoute
            exact
            path="/contact"
            component={Contact}
            {...authDetails}
          />
          <PublicRoute
            exact
            path="/shop/books"
            component={Book}
            {...authDetails}
          />
          <PublicRoute
            exact
            path="/reactivate-account"
            component={reactivateAccount}
          />
          <PublicRoute
            exact
            path="/resource"
            component={Resource}
            {...authDetails}
          />
          <PublicRoute
            exact
            path="/privacy-policy"
            component={PrivacyPolicyPage}
            {...authDetails}
          />
          <PublicRoute
            exact
            path="/terms-conditions"
            component={TermsConditionPage}
            {...authDetails}
          />
          <PublicRoute
            exact
            path="/cookie-policy"
            component={CookiePolicyPage}
            {...authDetails}
          />
          {/* Only for third tier users */}
          {/* <PublicRoute path="/nourish" component={KatiesCornerRoutes} history={history} /> */}

          <Route component={Page404} {...authDetails} />
        </Switch>
        {(false || authDetails.isAuthenticated && !hasCompletedForTheMonth) ? (
          <ModalWrapper
            minHeight="680px"
            maxWidth="500px"
            isOpen
            showClose
            onRequestClose={() => {
              props.acceptSecondTime();
              setHasCompletedForTheMonth(true);
            }}
          >
            <div className="modalWrapper paywall-modal">
              <div className="modal-image-wrapper">
                <figure>
                  <img src={modalImg} className="img_fluid"></img>
                </figure>
              </div>
              <div className="modal-body-wrapper">
                <h3>Pick your Meal Plans!</h3>
                <p>
                  You can now pick your Favorite Mealplans and get the latest Mealplan every week directly on your email.
                </p>

              </div>
              <div className="modal-footer-wrapper">
                <Button
                  btnClass="primary btn-md"
                  btnText="Proceed to pick"
                  onClick={() => {
                    localStorage.setItem('hasCompletedForTheMonth', true);
                    localStorage.setItem('hasCompletedForTheMonthLastDate', moment().month());
                    console.log('history ', history);
                    window.location.href = "/calendar?pick-your-plan=true";
                    setHasCompletedForTheMonth(true);

                  }}
                />
                <p
                  onClick={() => {
                    localStorage.setItem('hasCompletedForTheMonth', true);
                    localStorage.setItem('hasCompletedForTheMonthLastDate', moment().month());
                    setHasCompletedForTheMonth(true);
                  }}
                >
                  Remind me later
                </p>
              </div>
            </div>
          </ModalWrapper>
        ) : null}
        <Footer {...props} />
      </Router>
    </>
  );
};
const mapStateToProps = (state) => ({
  loginStatus: getAuthStatus(state),
  toasts: getToastMessage(state),
  groceryListThreshold: getGroceryListThresholdNotification(state),
  footerStatus: state.getIn(["settings", "footerStatus"]),
});

const dispatchActionToProps = (dispatch) => ({
  appEssentialFetch: bindActionCreators(appEssentialFetch, dispatch),
  replaceIngredients: bindActionCreators(replaceIngredients, dispatch),
  removeGroceryListLimitNotification: bindActionCreators(
    removeGroceryListLimitNotification,
    dispatch
  ),
  acceptFirstTime: bindActionCreators(acceptFirstTime, dispatch),
  acceptSecondTime: bindActionCreators(acceptSecondTime, dispatch),
  changeFooterStatus: bindActionCreators(changeFooterStatus, dispatch),
});

export default connect(mapStateToProps, dispatchActionToProps)(toJS(Routes));
