import { select, put, take, call, delay } from "redux-saga/effects";

import isNil from "lodash/isNil";
import isEmpty from "lodash/isEmpty";
import {
  getAuthStatus,
  getCachedUserToken,
  getExistingRecipes,
  getCachedObjectRecipeSlug,
  getCachedObjectWorkoutSlug,
  getRecipeFromAnyListBySlug,
  getWorkoutFromAnyListBySlug,
  getBlogFromAnyListBySlug,
  getToken,
} from "../selectors";

import omit from "lodash/omit";
import objectSpecificEssential from "../objectSpecificEssential";
import fetchRecipeDetails from "../fetchRecipeDetails";
import fetchWorkoutDetails from "../fetchWorkoutDetails";
import fetchBlogDetails from "../fetchBlogDetails";

import usersTask from "../subtask/users";
import recipesTask from "../subtask/recipes";

import * as actions from "../../actions/actionTypes";
import pushToSentry from "../../helpers/pushToSentry";

export default function* objectItemsFetch(payload) {
  const {
    recipe: { recipeSlug } = {},
    workout: { workoutSlug } = {},
    blog: { blogSlug } = {},
  } = payload;

  let slug;
  const currentUserToken = yield select(getToken);

  // Only if token is present this should happen
  if (recipeSlug) {
    // get the recipe slug which is cached if
    let recipeSlugFromFirstFetch = yield select(getCachedObjectRecipeSlug);

    yield put({
      type: actions.GET_OBJECT_SPECIFIC_ESSENTIAL,
      payload: {
        objectType: "recipe",
      },
    });

    // Get the entire state
    const getState = (state) => state;
    const appState = yield select(getState);

    // Only one among recipe / workout / videos / blogs will be running
    if (recipeSlug && recipeSlug !== recipeSlugFromFirstFetch) {
      slug = recipeSlug;

      let data;

      const cachedRecipe = getRecipeFromAnyListBySlug(appState, recipeSlug);

      if (cachedRecipe && !cachedRecipe.isEmpty()) {
        // Omit uncached things

        data = omit(cachedRecipe.toJS(), ["favorite_count"]);

        yield put({
          type: actions.PUSH_OBJECT_TO_STACK,
          payload: {
            objectType: "recipe",
            data, // Will have recipes, categories and related recipes
            relatedRecipes: [],
            tags: [],
            paywall: !currentUserToken ? true : false,
          },
        });
      } else {
        data = {
          slug: recipeSlug,
        };
        // Push an empty object to stack
        yield put({
          type: actions.PUSH_OBJECT_TO_STACK,
          payload: {
            objectType: "recipe",
            data, // Will have recipes, categories and related recipes
            relatedRecipes: [],
            tags: [],
            paywall: !currentUserToken ? true : false,
          },
        });
      }

      try {
        // Now get the actual data
        const {
          objectType,
          updatedData, // Will have recipes, categories and related recipes
          relatedRecipes,
          tags,
          meteredCount,
          title, // might come
          limitReached, // might come,
          images, // might come,
          story,
          variations,
          favoriteCount,
        } = yield call(fetchRecipeDetails, {
          payload: {
            recipeSlug,
            objectType: "recipe",
          },
        });

        yield put({
          type: actions.UPDATE_OBJECT_TO_STACK,
          payload: {
            objectType: "recipe",
            data: updatedData, // Will have recipes, categories and related recipes
            relatedRecipes,
            tags,
            meteredCount,
            limitReached,
            title,
            images,
          },
        });

        if (!limitReached) {
          let recipeId = data.id || updatedData.id;
          // Fetch Everything related to recipe

          const { commentsData, notesData, commentsCount } = yield call(
            objectSpecificEssential,
            {
              payload: {
                objectType: "recipe",
                objectId: recipeId,
              },
            }
          );
          // When the current object is recipe
          yield put({
            type: actions.GET_OBJECT_SPECIFIC_ESSENTIAL_SUCCESS,
            payload: {
              commentsData,
              notesData,
              commentsCount,
              objectType: "recipe",
              objectId: recipeId,
              slug,
              forUser: currentUserToken,
            },
          });
        } else {
          yield put({
            type: actions.UPDATE_OBJECT_TO_STACK_FAILURE,
            payload: {
              objectType: "recipe",
              message: "limitExceeded",
              title,
              images,
              story,
              variations,
              favoriteCount,
            },
          });
        }
      } catch (e) {
        console.log(e);
        pushToSentry(e);
        yield put({
          type: actions.UPDATE_OBJECT_TO_STACK_FAILURE,
          payload: {
            objectType: "recipe",
            message: "limitExceeded",
            title: (data && data.title) || (updatedData && updatedData.title),
            title,
            images,
            story,
            variations,
            favoriteCount,
          },
        });
      }
    }
  } else if (workoutSlug) {
    slug = workoutSlug;

    yield put({
      type: actions.GET_OBJECT_SPECIFIC_ESSENTIAL,
      payload: {
        objectType: "workout",
      },
    });

    // Get the entire state
    const getState = (state) => state;
    const appState = yield select(getState);

    const cachedWorkout = getWorkoutFromAnyListBySlug(appState, workoutSlug);

    if (cachedWorkout && !cachedWorkout.isEmpty()) {
      // Omit uncached things

      data = omit(cachedWorkout.toJS(), ["favorite_count"]);

      yield put({
        type: actions.PUSH_OBJECT_TO_STACK,
        payload: {
          objectType: "workout",
          data, // Will have recipes, categories and related recipes
          relatedWorkouts: [],
          tags: [],
          paywall: !currentUserToken ? true : false,
        },
      });
    } else {
      data = {
        slug: workoutSlug,
      };
      // Push an empty object to stack
      yield put({
        type: actions.PUSH_OBJECT_TO_STACK,
        payload: {
          objectType: "workout",
          data, // Will have recipes, categories and related recipes
          relatedWorkouts: [],
          tags: [],
          paywall: !currentUserToken ? true : false,
        },
      });
    }

    // Now get the actual data
    const {
      objectType,
      updatedData, // Will have recipes, categories and related recipes
      relatedWorkouts,
      tags,
      meteredCount,
      limitReached,
      title,
      images,
      calorie_burn,
      difficulty,
      favorite_count,
      introduction,
      message,
      status,
      time,
    } = yield call(fetchWorkoutDetails, {
      payload: {
        workoutSlug,
        objectType: "workout",
      },
    });

    yield put({
      type: actions.UPDATE_OBJECT_TO_STACK,
      payload: {
        objectType: "workout",
        data: updatedData, // Will have recipes, categories and related recipes
        relatedWorkouts,
        tags,
        meteredCount,
        limitReached,
        title,
        images,
      },
    });

    if (!limitReached) {
      let workoutId = data.id || updatedData.id;

      const { commentsData, notesData, commentsCount } = yield call(
        objectSpecificEssential,
        {
          payload: {
            objectType: "workout",
            objectId: workoutId,
          },
        }
      );

      yield put({
        type: actions.GET_OBJECT_SPECIFIC_ESSENTIAL_SUCCESS,
        payload: {
          commentsData,
          notesData,
          commentsCount,
          objectType: "workout",
          objectId: workoutId,
          forUser: currentUserToken,
        },
      });
    } else {
      yield put({
        type: actions.UPDATE_OBJECT_TO_STACK_FAILURE,
        payload: {
          objectType: "workout",
          message: "limitExceeded",
          title: title,
          images: images,
          calorie_burn,
          difficulty,
          favorite_count,
          introduction,
          message,
          status,
          time,
        },
      });
    }
  } else if (blogSlug) {
    slug = blogSlug;

    yield put({
      type: actions.GET_OBJECT_SPECIFIC_ESSENTIAL,
      payload: {
        objectType: "blog",
      },
    });

    // Get the entire state
    const getState = (state) => state;
    const appState = yield select(getState);

    const cachedBlog = getBlogFromAnyListBySlug(appState, blogSlug);

    if (cachedBlog && !cachedBlog.isEmpty()) {
      // Omit uncached things

      data = omit(cachedBlog.toJS(), ["favorite_count"]);

      yield put({
        type: actions.PUSH_OBJECT_TO_STACK,
        payload: {
          objectType: "blog",
          data, // Will have blogs, categories and related blogs
          relatedBlogs: [],
          categories: [],
        },
      });
    } else {
      data = {
        slug: blogSlug,
      };
      // Push an empty object to stack
      yield put({
        type: actions.PUSH_OBJECT_TO_STACK,
        payload: {
          objectType: "blog",
          data, // Will have blogs, categories and related blogs
          relatedBlogs: [],
          categories: [],
        },
      });
    }

    // Now get the actual data
    const {
      objectType,
      data: updatedData, // Will have blogs, categories and related blogs
      relatedBlogs,
    } = yield call(fetchBlogDetails, {
      payload: {
        blogSlug,
        objectType: "blog",
      },
    });

    yield put({
      type: actions.UPDATE_OBJECT_TO_STACK,
      payload: {
        objectType: "blog",
        data: updatedData, // Will have blogs, categories and related blogs
        relatedBlogs,
        categories,
      },
    });

    let blogId = data.id || updatedData.id;

    const { commentsData, notesData, commentsCount } = yield call(
      objectSpecificEssential,
      {
        payload: {
          objectType: "blog",
          objectId: blogId,
        },
      }
    );

    yield put({
      type: actions.GET_OBJECT_SPECIFIC_ESSENTIAL_SUCCESS,
      payload: {
        commentsData,
        notesData,
        commentsCount,
        objectType: "blog",
        objectId: blogId,
        forUser: currentUserToken,
      },
    });
  }
}
